/*
font-family: 'Montserrat', sans-serif;
font-family: 'Oswald', sans-serif;
*/
body{
  background-color: #FCFFFF;
}

h1{
  font-size: 48pt;
  font-family: 'Oswald', sans-serif;
  text-align: center;
}

h2{
  font-size: 30pt;
  font-family: 'Oswald', sans-serif;
  text-align: center;
}

h3{
  font-size: 20pt;
  font-family: 'Oswald', sans-serif;
  text-align: center;
}

p, a{
  font-size: 16pt;
  font-family: 'Montserrat', sans-serif;
}

.background-pic{
  background-image: url("./img/background.jpg");
  background-size: cover;
  opacity: 0.55;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media( max-width: 414px ){
  h1{
    font-size: 30pt;
  }

  h2{
    font-size: 24pt;
  }

  p, a{
    font-size: 14pt;
  }
}

