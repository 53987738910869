.footer{
    background-color: #e8f8ff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
}

.footer p{
    text-align: center;
    color: #0078a9;
    margin: 0;
}

.copyright{
    text-align: center;
    padding-top: 10px;
}

.copyright a{
    color: #0078a9;
    font-size: 12pt;
}

.copyright p{
    font-size: 10pt;
}