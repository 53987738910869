.slideshow{
    display: grid;
    grid-template-rows: repeat( 5, auto );
    position: relative;
}

.slideshow-container{
    display: grid;
    grid-template-columns: repeat( 3, auto );
    position: relative;

    grid-row: 1 / 6;
    grid-column: 1;
    z-index: -1;
}

.button-container{
    grid-column: 1;
    grid-row: 2 / 3;
    z-index: 1;

    margin-top: 300px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.slideshow-button{
    font-size: 30pt;
    background-color: transparent;
    color: white;
    border-style: none;
    outline: none;
    
    text-align: center;
    padding: 10px 15px;
}

.slideshow-button:hover{
    background-color: #0078a9;
}

.dot-buttons-container{
    text-align: center;
    z-index: 2;
    grid-column: 1 / 2;
    grid-row: 5 / 6;
}

.dot{
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin: 20px 2px;
    background-color: #f5feff;
    border-radius: 50%;
    display: inline-block;
}

.active, .dot:hover{
    background-color: #0078a9;
}

.image-container{
    z-index: -1;
    grid-column: 1 / 4;
    grid-row: 1;
    background-color: black;
}

.slideshow-image{
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    opacity: 0.45;
}

.original-img{
    opacity: 0.8;
}

.top-container{
    margin-top: 200px;
    grid-column: 1 / 4;
    grid-row: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.text-container{
    width: 100vw;
}

.color{
    color: white;
}

.slideshow-title{
    font-size: 60pt;
}

.slideshow-text{
    font-size: 35pt;
    text-align: center;
    padding-left: 300px;
    padding-right: 300px;
}

@media( max-width: 1366px ){
    .slideshow-image{
        width: 100vw;
        max-width: 100%;
        height: auto;
    }

    .slideshow-title{
        font-size: 50pt;
    }

    .slideshow-text{
        font-size: 25pt;
        padding-left: 190px;
        padding-right: 190px;
    }
}

@media( max-width: 990px ){
    .slideshow-title{
        margin-bottom: 0;
    }
    
    .slideshow-text{
        font-size: 25pt;
        padding-left: 80px;
        padding-right: 80px;
    }

    .dot{
        width: 20px;
        height: 20px;
        margin: 10px 2px;
    }
}

@media( max-width: 768px ){
    .slideshow-title{
        font-size: 40pt;
        margin-bottom: 0;
    }

    .slideshow-text{
        font-size: 25pt;
        padding-left: 80px;
        padding-right: 80px;
    }

    .top-container{
        margin-top: 120px;
        margin-bottom: 5px;
        padding-bottom: 15px;
    }

    .button-container{
        margin-top: 250px;
    }

    .dot{
        width: 20px;
        height: 20px;
        margin: 10px 2px;
    }
}

@media( max-width: 540px ){
    .slideshow-title{
        font-size: 30pt;
        margin-bottom: 0;
    }

    .slideshow-text{
        font-size: 15pt;
        padding-left: 80px;
        padding-right: 80px;
    }

    .top-container{
        margin-top: 120px;
        margin-bottom: 5px;
        padding-bottom: 15px;
    }

    .button-container{
        margin-top: 200px;
    }

    .dot{
        width: 20px;
        height: 20px;
        margin: 2px 2px;
    }
}

@media( max-width: 414px ){
    .slideshow-image{
        width: 100vw;
        height: 100%;
        max-width: 100%;
    }
    
    .slideshow-title{
        font-size: 24pt;
        margin-bottom: 0;
    }

    .slideshow-text{
        font-size: 12pt;
        padding-left: 55px;
        padding-right: 55px;
    }

    .top-container{
        margin-top: 150px;
        margin-bottom: 5px;
        padding-bottom: 15px;
    }

    .button-container{
        margin-top: 200px;
    }

    .dot{
        width: 20px;
        height: 20px;
        margin: 2px 2px;
    }
}