.leadership{
  background-color: black;
  position: relative;
  z-index: 1;
  padding-top: 45px;
  padding-bottom: 45px;
}

.leadership h1{
  color: #F5FEFF;
  margin-top: 0;
}

.leadership #text{
  padding: 55px 545px;
  text-align: center;
  color: #F5FEFF;
}

.profile-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; 
}

.profile-text, .nametag{
  background-color: white;
}

.profile{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
}

.top{
  width: 690px;
  height: 480px;

  display: grid;
  grid-template-columns: repeat(7, auto);
  position: relative;
}

.top img{
  width: 690px;
  height: 450px;
  z-index: -1;
  grid-column: 1 / 8;
  grid-row: 1;
}

.nametag{
  grid-column: 7;
  grid-row: 1;
  z-index: 1;
  margin-top: 380px;

  width: 300px;
  height: 85px;
}

.nametag h3{
  margin: 7px;
  color: #0078a9;
}

.nametag p{
  margin: 0px 15px 7px 15px;
  color: #667479;
  text-align: center;
}

#ben{
  margin-right: 16px;
}

.profile-text{
  display: none;
  width: 706px;
  height: 480px;
}

.profile-text p{
  color: #667479;
  padding-left: 15px;
  padding-right: 15px;
}

.profile-text a{
  color: #0078a9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
}

.linkedin-icon{
  width: 32px;
  height: 32px;
  padding-right: 5px;
}

#ben-pic{
  background: url("../img/profile/Ben.png") no-repeat;
  background-size: cover;
}

#kim-pic{
  background: url("../img/profile/Kim.png") no-repeat;
  background-size: cover;
}

@media( max-width: 1366px ){
  .leadership h1{
    margin: 0;
  }

  .leadership #text{
    margin: 0;
    padding: 15px 35px;
    text-align: center;
    margin-bottom: 16px;
  }

  #ben{
    margin-right: 0;
    margin-bottom: 16px;
  }

  #kim{
    flex-direction: column-reverse;
  }

  .profile-text{
    width: 690px;
    height: auto;
    padding-bottom: 5px;
  }
}

@media( max-width: 540px ){
  .profile-container{
    margin: 15px;
  }

  .top{
    width: 100vw;
  }

  .profile-text{
    width: 100vw;
    height: auto;
    padding-bottom: 15px;
  }
}

@media( max-width: 414px ){
  .top{
    height: 250px;
  }

  .nametag{
    margin-top: 180px;
    width: auto;
    height: 60px;
  }

  .nametag h3{
    font-size: 14pt;
    margin-bottom: 2px;
  }

  .nametag p{
    font-size: 10pt;
  }
}

