.nav-bar{
    background-color: #E8F8FF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 35px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
  
    border-bottom: 5px solid #0078a9;
  }
  
  #bk-logo{
    width: 490px;
    height: 78px;
  }

  .menu-dropdown-container{
    display: flex;
    justify-content: center;
  }
  
  .links-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .lpad{
    margin-right: 30px;
  }
  
  .link{
    color: #0078a9;
    text-decoration: none;
  }

  .portfolio-dropdown-btn {
    background-color: #E8F8FF;
    color: #0078a9;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none;
  }
  
  .portfolio-dropdown-container {
    position: relative;
    display: inline-block;
  }
  
  .portfolio-links {
    display: none;
    position: absolute;
    background-color: #0078a9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .portfolio-links a {
    color: #F5FEFF;
    padding: 12px;
    text-decoration: none;
    display: block;
    text-align: center;
    margin: 0;
  }
  
  .portfolio-dropdown-container:hover .portfolio-links {
    display: block;
  }

  .small-screen{
    display: none;
  }

  .menu-dropdown-btn{
    display: none;
  }

@media( max-width: 1530px ){
  .lpad{
    margin-right: 15px;
    text-align: center;
  }
}

@media(max-width: 1400px ){
  .nav-bar{
    align-items: center;
  }

  .menu-dropdown-btn{
    display: block;
    background-color: #e8f8ff;
    color: #0078a9;
    border-color: #0078a9;
    padding: 16px 30px;
    font-size: 16px;
    outline: none;
    margin-bottom: 2px;
  }
  
  .menu-dropdown-container{
    position: relative;
    display: block;
  }
  
  .links-container{
    display: none;
    position: absolute;
    background-color: #0078a9;
    z-index: 1;
    right: 0;
  }
  
  .links-container a{
    color: #F5FEFF;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    margin: 0;
  }
  
  .menu-dropdown-container a:hover{
    background-color: #F5FEFF;
    color: #0078a9;
  }
  
  .showMenu{
    display: block;
  }

  .portfolio-dropdown-container{
    display: none;
  }
}

@media( max-width: 540px ){
  .nav-bar{
    padding: 16px;
  }

  #bk-logo{
    width: 350px;
    height: auto;
  }

  .lpad, .link{
    font-size: 10pt;
  }
}

@media( max-width: 414px ){
  .nav-bar{
    flex-direction: column;
  }

  .links-container{
    left: 0;
  }
}

  