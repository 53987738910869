.focus-and-criteria{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    position: relative;
    padding-bottom: 45px;
    z-index: 1;
  }
  
  .focus-and-criteria h1{
    color: #F5FEFF;
  }
  
  .focus-and-criteria #text{
    padding: 55px 545px;
    text-align: center;
    color: #F5FEFF;
  }
  
  .bullets-container{
    padding-left: 150px;
    padding-right: 150px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .bullet{
    margin-right: 16px;
    display: grid;
    grid-template-columns: auto auto auto;
    position: relative;
  }
  
  .bullet-icon{
    display: flex;
    justify-content: center;
    align-items: center;
  
    border: 3px solid #0078a9;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    margin: 0;
    background-color: white;
  
    grid-column: 2 / 3;
    grid-row: 1;
    z-index: 100;
  }
  
  .icon{
    width: 44px;
    height: 44px;
  }
  
  .bullet-text-box{
    width: 260px;
    height: 169px;
    border: 3px solid #0078a9;
    background-color: white;
  
    grid-column: 1 / 4;
    grid-row: 1;
    margin-top: 36px;
  }
  
  #growth{
    margin: 0;
  }
  
  .bullet-text{
    margin: 0;
    padding-right: 22px;
    padding-left: 22px;
    padding-top: 53px;
    text-align: center;
    color: #0078a9;
  }

  @media( max-width: 1366px ){
    .focus-and-criteria #text{
      padding: 15px 35px;
      margin: 0px;
      text-align: center;
    }

    .bullets-container{
      padding-left: 35px;
      padding-right: 35px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    .bullet, #growth{
      margin-top: 15px;
    }
  }

  @media( max-width: 768px ){
    .focus-and-criteria h1{
      padding-left: 15px;
      padding-right: 15px;
    }
  }