.news h1{
    color: #0078a9;
}

.twitter-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.timeline-container{
    overflow: scroll;
    height: 400px;
    border: 1px solid #667479;
    border-radius: 10px;
    margin: 5px;
}

@media( min-width: 1025px ){
    .timeline-container{
        width: 400px;
    }
}

@media( max-width: 1024px ){
    .timeline-container{
        width: 800px;
        height: 600px
    }

    #emocha, #haasalert, #huntress{
        margin-bottom: 20px;
    }
}

@media( max-width: 768px ){
    .twitter-container{
        flex-direction: column;
        align-items: center;
    }

    .timeline-container{
        width: 550px;
    }
}

@media( max-width: 540px ){
    .timeline-container{
        width: 450px;
    }
}

@media( max-width: 414px ){
    .timeline-container{
        width: 300px;
    }
}