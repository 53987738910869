.what-we-do{
  padding-bottom: 27px;
}

.what-we-do h1{
    color: #0078a9;
    margin: 27px;
}
  
#wwd-p{
  padding: 27px 545px;
  text-align: center;
  color: #667479;
  margin: 0;
}

.cards-container{
  padding-left: 140px;
  padding-right: 140px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

  /*********************** CARDS **************************/
  /*
  Copyright (&copy;) 2021 by Saravana Kumar (https://codepen.io/SaroDiwa/pen/pWeWdP)
  Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
  The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
  */

  /* cards stuff */
  .snip1543 {
    color: #f5feff;
    display: inline-block;
    margin: 10px 5px;
    max-width: 450px;
    max-height: 294px;
    min-width: 230px;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  /* words transition */
  .snip1543 *,
  .snip1543 *:before,
  .snip1543 *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.45s ease;
    transition: all 0.45s ease;
  }
  
  /* image sizing */
  .snip1543 img {
    backface-visibility: hidden;
    width: 450px;
    vertical-align: top;
    height: 294px;
  }
  
  /* red ribbon 1 */
  .snip1543:before,
  .snip1543:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    background-color: #0078a9;
    opacity: 0.5;
    -webkit-transition: all 0.45s ease;
    transition: all 0.45s ease;
  }
  
  /* red ribbon 1 bottom diagonal */
  .snip1543:before {
    -webkit-transform: skew(30deg) translateX(-80%);
    transform: skew(30deg) translateX(-80%);
  }
  
  /* red ribbon 1 top diagonal */
  .snip1543:after {
    -webkit-transform: skew(-30deg) translateX(-70%);
    transform: skew(-30deg) translateX(-70%);
  }
  
  /* text over image */
  .snip1543 figcaption {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    bottom: 0;
    padding: 25px 40% 25px 20px;
  }
  
  .snip1543 figcaption h3{
    margin-left: 15px;
    line-height: 30px;
    text-align: left;
  }
  
  .snip1543 figcaption p{
    margin: 15px;
  }
  
  /* red ribbon 2 */
  .snip1543 figcaption:before,
  .snip1543 figcaption:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #0078a9;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    content: '';
    opacity: 0.5;
    z-index: -1;
  }
  
  /* red ribbon 2 bottom diagonal */
  .snip1543 figcaption:before {
    -webkit-transform: skew(30deg) translateX(-100%);
    transform: skew(30deg) translateX(-100%);
  }
  
  /* red ribbon 2 top diagonal */
  .snip1543 figcaption:after {
    -webkit-transform: skew(-30deg) translateX(-90%);
    transform: skew(-30deg) translateX(-90%);
  }
  
  /* text spacing */
  .snip1543 h3,
  .snip1543 p {
    margin: 0;
    opacity: 0;
    letter-spacing: 1px;
  }
  
  /* title sizing */
  .snip1543 h3 {
    line-height: 1em;
    text-transform: uppercase;
  }
  
  .snip1543 a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  
  /* transition words to screen */
  .snip1543:hover h3,
  .snip1543.hover h3,
  .snip1543:hover p,
  .snip1543.hover p {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0.9;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  
  /* transition red ribbon 1 bottom */
  .snip1543:hover:before,
  .snip1543.hover:before {
    -webkit-transform: skew(30deg) translateX(-20%);
    transform: skew(30deg) translateX(-20%);
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
  }
  
  /* transition red ribbon 1 top */
  .snip1543:hover:after,
  .snip1543.hover:after {
    -webkit-transform: skew(-30deg) translateX(-10%);
    transform: skew(-30deg) translateX(-10%);
  }
  
  /* transition red ribbon 2 bottom */
  .snip1543:hover figcaption:before,
  .snip1543.hover figcaption:before {
    -webkit-transform: skew(30deg) translateX(-40%);
    transform: skew(30deg) translateX(-40%);
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
  }
  
  /* transition red ribbon 2 top */
  .snip1543:hover figcaption:after,
  .snip1543.hover figcaption:after {
    -webkit-transform: skew(-30deg) translateX(-30%);
    transform: skew(-30deg) translateX(-30%);
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  @media( max-width: 1366px ){
    #wwd-p{
      margin: 0;
      padding: 15px 35px;
      text-align: center;
    }
  }

  @media( max-width: 540px ){
    .cards-container{
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  @media( max-width: 414px ){
    .cards-container{
      padding-left: 20px;
      padding-right: 20px;
    }

    .snip1543 figcaption{
      padding: 25px 30% 25px 20px;
    }

    .snip1543 figcaption h3{
      margin-left: 5px;
      line-height: 30px;
      text-align: left;
    }
    
    .snip1543 figcaption p{
      margin: 5px;
    }
  }

  @media( max-width: 320px ){
    .snip1543 figcaption{
      padding: 10px 20% 10px 10px;
    }
  }