.portfolio{
    color: #0078a9;
}

.h2-logo {
    text-align: center; 
    border-bottom: 3px solid #0078a9; 
    line-height: 0.1em;
    margin-left: 315px;
    margin-right: 315px;
} 

.span-logo{ 
    background:#FCFFFF; 
    padding:0 10px; 
    color: #0078a9
}

.companies{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 140px;
    padding-right: 140px;
}

.portfolio-link{
    text-decoration: none;
}

.logo-container{
    display: grid;
    grid-template-columns: repeat( 2, auto );
    position: relative;
    margin: 16px;
}

.logo-width{
    width: 200px;
}

.logo{
    height: 200px;
    padding: 5px;
}

.logo-img{
    display: flex;
    align-items: center;
    z-index: -1;
    grid-column: 1 / 3;
    grid-row: 1;
}

.logo-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 3px solid #e8f8ff;
    color: #0078a9;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;

    grid-column: 1 / 3;
    grid-row: 1;

    display: none;
}

.logo-text h3{
    margin-top: 15px;
    margin-bottom: 12px;
}

.logo-text p{
    font-size: 16px;
    color: #667479;
    text-align: center;
}

.year{
    font-family: "Montserrat", sans-serif;
    text-align: center;
    margin: 0;
}

#affil{
    color: #0078a9;
}

@media( max-width: 1024px ){
    .h2-logo{
        margin-left: 100px;
        margin-right: 100px;
    }

    .companies{
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media( max-width: 540px ){
    .h2-logo{
        margin-left: 80px;
        margin-right: 80px;
    }

    .companies{
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media( max-width: 414px ){
    .h2-logo{
        margin-left: 45px;
        margin-right: 45px;
    }
}

@media( max-width: 320px ){
    .h2-logo{
        margin-left: 25px;
        margin-right: 25px;
    }
}